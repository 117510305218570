import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Table, Th, Tr, Td, Thead, Tbody } from "../table/table.component";
import Product from "./Product.interface";
import ProductTitleEan from "./ProductTitleEan/ProductTitleEan";

import {
  formatAmount,
  formatDiscountLevels,
} from "../../utils/text-formatting.utils";
import { resetMark } from "../../store/actions/product-mark.action";
import { environment } from "../../environments";
import {
  getDiscountLevel,
  priceWithDiscount,
  getTotalWithoutTaxes,
  getTotalWithTaxes,
  getDiscountLevels,
  getAdjustedLevels,
  keepBestDiscounts,
} from "../../utils/prices";

// import Cookies from "js-cookie";
import Cookies from "universal-cookie";

const StyledTr = styled(Tr)<{ color?: string; bottomColor?: string }>`
  background-color: ${({ color }) => (color ? color : "white")};
  border-bottom: ${({ bottomColor }) =>
    bottomColor ? `1px solid ${bottomColor} !important;` : "none"};
  *::selection {
    background-color: transparent;
  }
`;

const StyledTh = styled(Th)<{
  bottomColor?: string;
  centered?: boolean;
  minWidth?: string;
}>`
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  border-bottom: ${({ bottomColor }) =>
    bottomColor ? `1px solid ${bottomColor} !important;` : "none"};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  padding: 0 8px;
`;

const StyledTd = styled(Td)<{ centered?: boolean }>`
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  padding: 0 8px;
`;

// DEBUG: this is temporary
const Img = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  height: 40px;
  width: 40px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const ImageTd = styled(Td)`
  padding: 0 !important;
`;

// DEBUG: I should have a way to directly open & scroll to the right category from a parameter.
const OrderRow = ({
  id,
  imagePath,
  title,
  brand,
  ean,
  batch,
  tva,
  price,
  discountLevelForProduct,
  product,
  color = undefined,
  priceWithTaxesColumn,
  orderedProducts,
  baseDiscounts,
  discountThresholds,
  discountGroupProducts,
}) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const rowRef = useRef(null);

  const user = useSelector((state) => state.user);
  const productMark = useSelector((state) => state.productMark);

  const [hover, setHover] = useState(false);
  const [orderQuantity, setOrderQuantity] = useState("0");

  useEffect(() => {
    if (!productMark.type) return;
    if (productMark.type === "product" && id === productMark.id) {
      dispatch(resetMark);
      rowRef.current.scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
      });
    }
  }, [productMark, dispatch, id]);

  useEffect(() => {
    if (!orderedProducts) return;
    if (orderedProducts[id]) {
      setOrderQuantity(orderedProducts[id]);
    } else {
      setOrderQuantity("0");
    }
  }, [id, orderedProducts]);

  const handleOnMouseEnter = (event) => {
    setHover(true);
  };

  const handleOnMouseLeave = (event) => {
    setHover(false);
  };

  const discountLevels = getDiscountLevels(baseDiscounts, discountThresholds);
  const adustLevelsTest = getAdjustedLevels(
    product.product_id,
    discountLevels,
    discountGroupProducts,
    orderedProducts
  );
  const discountLevelsFormatted = formatDiscountLevels(
    keepBestDiscounts(discountLevels)
  );

  const orderQuantityInt = parseInt(orderQuantity, 10);
  const discountLevelComputed = getDiscountLevel(
    adustLevelsTest,
    orderQuantityInt
  );

  const discountLevelToTakeIntoAccount =
    discountLevelForProduct ?? discountLevelComputed;

  const pricePostDiscount = priceWithDiscount(
    price,
    discountLevelToTakeIntoAccount
  );
  const totalWithoutTaxes = getTotalWithoutTaxes(
    price,
    orderQuantityInt,
    discountLevelToTakeIntoAccount
  );
  const totalWithTaxes = getTotalWithTaxes(totalWithoutTaxes, tva);

  return (
    <StyledTr ref={rowRef} color={color} bottomColor="#f3f3f4">
      <ImageTd>
        <Img>
          <img src={`${environment.imageBasePath}${imagePath}`} alt={"image"} />
        </Img>
      </ImageTd>
      <StyledTd>
        <Link to={`/produit/${id}`}>
          <ProductTitleEan
            title={title}
            ean={ean}
            brand={brand}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </Link>
      </StyledTd>
      <StyledTd centered>
        {hover ? (
          <pre>{discountLevelsFormatted}</pre>
        ) : (
          <pre style={{ color: "transparent" }}>{discountLevelsFormatted}</pre>
        )}
      </StyledTd>
      <StyledTd centered>{batch ? batch : "0"}</StyledTd>
      <StyledTd centered>{`${tva ? tva : "0"}%`}</StyledTd>
      <StyledTd centered>{formatAmount(price ? price : "0")}</StyledTd>
      <StyledTd centered>{`${discountLevelToTakeIntoAccount}%`}</StyledTd>
      <StyledTd centered>{formatAmount(pricePostDiscount)}</StyledTd>
      <>
        <StyledTd centered>{orderQuantity}</StyledTd>
        <StyledTd centered>{formatAmount(totalWithoutTaxes)}</StyledTd>
        {!priceWithTaxesColumn ? null : (
          <StyledTd centered>{formatAmount(totalWithTaxes)}</StyledTd>
        )}
      </>
    </StyledTr>
  );
};

const OrderRecapTable = ({
  productsQuantity,
  products,
  priceWithTaxesColumn = false,
  baseDiscounts,
  discountThresholds,
  discountGroupProducts,
}) => {
  // DEBUG
  const columns = [
    "",
    "Produit",
    "",
    "Volume",
    "TVA",
    "Prix",
    "Remise",
    "Prix net",
    "Qté",
    "Total HT",
  ];

  if (priceWithTaxesColumn) {
    columns.push("Total TTC");
  }

  return products && products.length > 0 ? (
    <Table className="responsive-static">
      <Thead>
        <StyledTr color="rgba(255, 255, 255, 0.9)">
          {columns.map((columnName, idx) => (
            <StyledTh
              key={idx}
              bottomColor="#f3f3f4"
              centered={idx !== 1}
              minWidth={idx >= columns.length - 2 ? "90px" : undefined}
            >
              {columnName}
            </StyledTh>
          ))}
          <StyledTh colSpan={2} />
        </StyledTr>
      </Thead>
      <Tbody>
        {products.map((product: Product, idx) => (
          <OrderRow
            key={idx}
            id={product.product_id}
            imagePath={product.image_path}
            title={product.product_title}
            brand={product.brand}
            ean={product.ean_id}
            discountLevelForProduct={product.discountLevel}
            batch={product.batch_quantity}
            tva={product.tva_percentage}
            price={product.price_eur}
            product={product}
            color={idx % 2 === 0 ? "rgba(255, 255, 255, 0.9)" : "#f3f9f9"}
            priceWithTaxesColumn={priceWithTaxesColumn}
            orderedProducts={productsQuantity}
            baseDiscounts={baseDiscounts[product.subRangeId]}
            discountThresholds={discountThresholds.filter((d) =>
              product.discount_groups?.includes(d.discount_group_id)
            )}
            discountGroupProducts={Object.fromEntries(
              Object.entries(discountGroupProducts).filter(([key]) =>
                product.discount_groups?.includes(+key)
              )
            )}
          />
        ))}
      </Tbody>
    </Table>
  ) : null;
};

export default OrderRecapTable;
